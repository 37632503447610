// Import FirebaseAuth and firebase.
import React from 'react';
import { useNavigate, useLocation, Navigate } from 'react-router-dom';
import StyledFirebaseAuth from './StyledFirebaseAuth';
import {getAuth, GoogleAuthProvider, EmailAuthProvider} from 'firebase/auth';
import FirebaseManager from './FirebaseManager';
import styles from './css/Login.module.css';

// FirebaseManager.getInstance().init();


function Login({loginOk}) {
  const navigate = useNavigate();
  const location = useLocation();
  const fromPath = location.state?.fromPath;
  const deployUrl = location.state?.deployUrl;
  if (loginOk) return <Navigate {...{to:"/signedIn", replace: true, ...(fromPath ? { state: { fromPath: fromPath, deployUrl: deployUrl } } : {})}} />
  // Configure FirebaseUI.
  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    signInOptions: [
      GoogleAuthProvider.PROVIDER_ID,
      EmailAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
      signInSuccessWithAuthResult: (authResult) => {
        // navigate(`/?from=`+(fromPath ? fromPath : 'none'), fromPath ? {replace: true, state: {fromPath: fromPath}} : {replace: true} );
        return false;
      },
    },
  };
  return (
    <div className={styles.container}>
      <img src="/txtlogo332.png" className={styles.logo} />
      <StyledFirebaseAuth
        uiConfig={uiConfig}
        firebaseAuth={getAuth()}
      />
      {/* <div className={styles.terms}>
        <span>
          You hereby agree to our terms of service.
        </span>
      </div> */}
    </div>
  );
}

export default Login;
