import React, {useState, useEffect, useContext} from 'react';
import { Typography, List, ListItem, ListItemButton, ListItemIcon, ListItemText, IconButton, LinearProgress, Box, Paper, Button, Divider, Tooltip, TextField, Tabs, Tab } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

import DoneIcon from '@mui/icons-material/Done';
import BlockIcon from '@mui/icons-material/Block';
import CircularProgress from '@mui/material/CircularProgress';
import EditIcon from '@mui/icons-material/Edit';
import {catchError} from 'rxjs/operators';
import Data from './Data';
import { RUN_STATE, RUN_STATE_GROUP, HUMAN_REVIEW_SUB_STATES } from './RunStates';
import Dots from './Dots';
import RunDate from './RunDate';
import { AccountContext, UserContext } from './AccountFrame';

export const TRIGGER = {IDLE:'idle', APPROVE:'approve', REJECT:'reject', ARCHIVE: 'done'}

const AgentRun = ({runDocId, runId, customMissingRunMsg}) => {
  const { user } = useContext(UserContext);
  const { account } = useContext(AccountContext);
  const accountId = account.id;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [run, setRun] = useState();
  const [selectedChildRun, setSelectedChildRun] = useState(0);
  const [webhooks, setWebhooks] = useState();
  
  useEffect(() => {
    setLoading(true);
    setError(null);
    const subscription = Data.getInstance().streamRun({accountId: accountId, ...(runDocId && {runDocId: runDocId}), ...(runId && {runId: runId})})
        .pipe(catchError(() => setError('There was an error loading the AI agent run.')))
        .subscribe(
            (newRun) => {
              setLoading(false);
              console.log("setting run with x steps:" + newRun.steps?.length)
              setRun({...newRun});
            },
        );

    return () => {
      subscription.unsubscribe();
    };
  }, [accountId, runDocId, runId]);
  
  useEffect(() => {
    if (!run || !["com.gotohuman.tests.lyzr.linkedin", "com.gotohuman.tests.email", "com.gotohuman.tests.agent", "com.gotohuman.demos.chatbot"].includes(run.agentId)) {
      setWebhooks(null)
      return;
    }
    const subscription = Data.getInstance().streamRunTriggeredWebhooks(accountId, run.docId)
        .subscribe(
            (hooks) => {
              setWebhooks([...hooks]);
            },
        );

    return () => {
      subscription.unsubscribe();
    };
  }, [accountId, run]);

  return (<Box sx={{
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '0px',
    paddingBottom: '0px',
  }}>
    <Paper sx={{ width: '90%', maxWidth: '1040px', color:'#444' }}>
      {loading && <LinearProgress color="secondary" />}
      {error && <Box sx={{color: 'red', padding:'10px', textAlign:'center'}}>{error}</Box>}
      {run && !run.docId && <Box sx={{padding:'20px', textAlign:'center'}}><i>{customMissingRunMsg || 'Run not found'}</i></Box>}
      {run && run.agent && <>
      <Box sx={{display:'flex', flexDirection:'row', alignItems:'center'}}>
        {runDocId && <Box sx={{flexGrow: 1, paddingLeft:'20px', paddingRight:'20px', paddingTop:'20px'}}>
          <Typography variant="h4">{run.agent.name}</Typography>
          {/* {run.lastModified && <Box sx={{display:'flex'}}><Typography variant="h8" sx={{color:'#ccc', fontSize:'14px', marginRight:'14px'}}>Last activity:</Typography><RunDate timeStampDate={run.lastModified} /></Box>} */}
        </Box>}
        <DropDownMenu accountId={accountId} user={user} runDocId={run.docId} />
      </Box>
      <Typography variant="h8" sx={{color:'#ccc', fontSize:'12px', paddingLeft:'20px', textAlign:'start'}}>Run: {run.runId}</Typography>
      <Box sx={{ padding:'40px', }}>
        <Timeline sx={{ [`& .${timelineItemClasses.root}:before`]: {flex: 0, padding: 0} }}>
          {run.steps
          .filter((step, index) => (step.state !== RUN_STATE.TASK_RUNNING && step.state !== RUN_STATE.REQUESTED_HUMAN && step.state !== RUN_STATE.SERVED_TO_HUMAN) || (index == (run.steps.length-1))) //only show running and human-approval items when latest item
          .map((step, index, arr) => {
            console.log("render runs " + arr.length)
            let item;
            let icon;
            let iconColor;
          if ((step.state === RUN_STATE.TASK_RUNNING)) {
            item = (index == arr.length-1) ? <RunStepRunning step={step} /> : <Box sx={{paddingTop:"4px"}}>Started</Box>; //not shown,filtered above
          } else if (step.state === RUN_STATE.TASK_DONE) {
            item = <RunStepDone step={step} />
            iconColor = "success";
            icon = <DoneIcon fontSize="inherit" sx={{fontSize:'14px'}} />
          } else if ((step.state === RUN_STATE.REQUESTED_HUMAN) || (step.state === RUN_STATE.SERVED_TO_HUMAN)) {
            item = (index == arr.length-1) ? (step.subState === HUMAN_REVIEW_SUB_STATES.MULTI_SELECT_FAN_OUT ? <RunStepForHumanSelection accountId={accountId} accountPath={account.path} user={user} step={step} run={run} /> : <RunStepForHuman accountId={accountId} accountPath={account.path} user={user} step={step} run={run} />) : ((step.state === RUN_STATE.REQUESTED_HUMAN) ? "Human approval requested" : "Result sent to human") //not shown,filtered above
          } else if ((step.state === RUN_STATE.APPROVED) || (step.state === RUN_STATE.REJECTED) || (step.state === RUN_STATE.ARCHIVED)) {
            item = <RunStepHumanReacted step={step} />
            iconColor = (step.state === RUN_STATE.APPROVED) ? "success" : ((step.state === RUN_STATE.REJECTED) ? "error" : "secondary");
            icon = ((step.state === RUN_STATE.APPROVED) || (step.state === RUN_STATE.ARCHIVED)) ? <DoneIcon fontSize="inherit" sx={{fontSize:'14px'}} /> : ((step.state === RUN_STATE.REJECTED) ? <BlockIcon fontSize="inherit" sx={{fontSize:'14px'}} /> : "");
          } else {
            item = <Box>{step.taskName || step.taskId} {step.state}</Box>
          }
            return <TimelineItem key={step.docId}><TimelineSeparator>{icon ? <TimelineDot color={iconColor} sx={{padding:'2px'}}>{icon}</TimelineDot> : <TimelineDot variant={(index == arr.length-1) ? "outlined" : "filled"} sx={{width:'20px', height:'20px'}} />}{((index < (arr.length-1)) || step.state === RUN_STATE.TASK_DONE) && <TimelineConnector />}</TimelineSeparator><TimelineContent>{item}</TimelineContent></TimelineItem>
          })}
          {run.steps && !!run.steps.length && (run.steps[run.steps.length - 1].state === RUN_STATE.TASK_DONE) && <TimelineItem key="runningDots"><TimelineSeparator><TimelineDot variant="outlined" sx={{width:'20px', height:'20px'}} /></TimelineSeparator><TimelineContent><Dots /></TimelineContent></TimelineItem>}
        </Timeline>
      </Box>
      </>
      }
    </Paper>
    { (run && run.steps && (run.steps.length > 0) && run.steps[run.steps.length-1].state === RUN_STATE.APPROVED && run.steps[run.steps.length-1].subState === HUMAN_REVIEW_SUB_STATES.MULTI_SELECT_FAN_OUT) ? (
      <Box sx={{width: '100%', display:'flex', flexDirection:'column', alignItems:'center'}}>
        <Tabs sx={{width: '90%', maxWidth: '1040px'}} value={selectedChildRun} onChange={(event, newValue) =>setSelectedChildRun(newValue)}>
          {run.steps[run.steps.length-1].actionValues.filter(av=>!!av.childRunId).map((av, index) => (
            <Tab label={`${index}: ${(av.text||'').substring(0,10)}`} key={av.childRunId} />
          )
          )}
        </Tabs>
        <AgentRun account={account} user={user} runId={run.steps[run.steps.length-1].actionValues.filter(av=>!!av.childRunId)[selectedChildRun].childRunId} customMissingRunMsg={"Waiting for run"} />
        {/* <Box>{childruns.find((r,i)=>i == selectedChildRun).docId}</Box> */}
      </Box>) : ""  
    }
    {/* {run && run.childRunIds && (run.childRunIds.length>0) && <Box sx={{display:'flex', flexDirection:'column', width: '90%', maxWidth: '1040px'}}><Tabs value={selectedChildRun} onChange={(event, newValue) =>setSelectedChildRun(newValue)}>
      {run.childRunIds.map((childrunId, index) => <Tab label={`${index}: ${childrunId}`} id={childrunId} />)}
      </Tabs>
      <AgentRun account={account} user={user} runId={run.childRunIds[selectedChildRun]} />
      </Box>
    } */}
    {webhooks && !!webhooks.length && <Paper sx={{ width: '90%', maxWidth: '1040px', background:'#ffe7a8', color:'#888', marginTop:'20px' }}>
      <Box sx={{display:'flex', flexDirection:'column', padding:'20px', gap:'12px'}}>
        <Typography>Debug info</Typography>
        {webhooks.map(hook => <Box key={hook.docId} sx={{display:'flex', flexDirection:'column', color:'#666'}}><div>Webhook triggered @ {hook.target} at {getDateStr(hook.triggeredAt) + ' at ' + getTimeStr(hook.triggeredAt)} with payload:</div><pre>{JSON.stringify(hook.payload, null, 2)}</pre></Box>)}
      </Box>
    </Paper>}
  </Box>)
};

export default AgentRun;

//----

const getDateStr = (timestamp) => new Date(timestamp.seconds * 1000).toDateString()
const getTimeStr = (timestamp) => new Date(timestamp.seconds * 1000).toLocaleTimeString()

const shortenString = (text) => {
  const shortened = text.slice(0,180)
  const needsShortening = (shortened.length < text.length)
  const toReturn = needsShortening ? (shortened + "...") : shortened;
  return [toReturn, needsShortening];
}

const previewString = (text) => {
  return shortenString(text)[0];
}

const RunStepRunning = ({step}) => {
  return (<Box sx={{display:'flex', flexDirection:'column', paddingTop:"4px"}}>
    <Box sx={{display:'flex', justifyContent:'space-between'}}>
      <Box sx={{fontWeight: 'medium'}}>{step.taskName || step.taskId || ""}</Box>
      <Typography sx={{fontSize:'14px', color:'#ccc', textAlign:'right'}}>{getDateStr(step.timestamp) + ' at ' + getTimeStr(step.timestamp)}</Typography>
    </Box>
    {step.taskDesc && <Box sx={{paddingTop:"4px"}}>{step.taskDesc}</Box>}
    <Dots/>
  </Box>)
}
const RunStepDone = ({step}) => {
  const [expanded, setExpanded] = useState(false);
  return (<Box sx={{display:'flex', flexDirection:'column', paddingTop:"4px", gap: '4px'}}>
    <Box sx={{display:'flex', justifyContent:'space-between'}}>
      <Box sx={{fontWeight: 'medium'}}>{step.taskName || step.taskId || ""}</Box>
      <Typography sx={{fontSize:'14px', color:'#ccc', textAlign:'right'}}>{getDateStr(step.timestamp) + ' at ' + getTimeStr(step.timestamp)}</Typography>
    </Box>
    {/* {step.taskName && <Box sx={{fontWeight: 'medium', paddingTop:"4px"}}>{step.taskName}</Box>} */}
    {step.taskDesc && <Box sx={{paddingTop:"4px"}}>{step.taskDesc}</Box>}
    {step.result && (Array.isArray(step.result) ? step.result : [step.result]).map((res, i) =>
    <Box key={i} sx={{padding:'20px', background:'#f8f8f8', borderRadius:'8px'}}>
      <Box sx={{paddingTop:"4px", whiteSpace:'pre-line'}}>{expanded ? res : previewString(res)}{shortenString(res)[1] ? <Typography sx={{display: 'inline', paddingLeft:'8px', fontSize:'14px', color:'#ccc', cursor:'pointer'}} onClick={()=>setExpanded(val => !val)}>{expanded ? 'Show less' : 'Show more'}</Typography> : ""}</Box>
    </Box>)}
  </Box>)
}
const RunStepForHuman = ({accountId, accountPath, user, step, run}) => {
  const [humanReactTrigger, setHumanReactTrigger] = useState(TRIGGER.IDLE);
  const [editing, setEditing] = useState(false);
  const [editedActionValues, setEditedActionValues] = useState(step.actionValues);
  const runDocId = run.docId
  const role = (!user || !user.access || !accountPath) ? null : user.access[accountPath];
  const isDevOrAdmin = !!role && ['dev', 'admin'].includes(role);
  
  useEffect(() => {
    if (humanReactTrigger === TRIGGER.IDLE) return () => { };
    const stateAfterReaction = (humanReactTrigger === TRIGGER.APPROVE) ? RUN_STATE.APPROVED : ((humanReactTrigger === TRIGGER.REJECT) ? RUN_STATE.REJECTED : RUN_STATE.ARCHIVED)
    const actionValuesWithEditedFlag = editedActionValues.map(val => {
      const oldValue = step.actionValues.find(el => el.id === val.id)
      const valHasChanged = oldValue && (oldValue.text !== val.text)
      return {...val, ...(step.allowEditing && {wasEdited: valHasChanged})};
    })
    const subscription = Data.getInstance().sendHumanReaction(user.id, user.email, accountId, runDocId, step.taskId, step.taskName, actionValuesWithEditedFlag, stateAfterReaction)
      .subscribe((x) => {
          setHumanReactTrigger(TRIGGER.IDLE);
        },
      );

    return () => {
      subscription.unsubscribe();
    };
  }, [accountId, runDocId, humanReactTrigger]);

  const onValueEdit = (valId, newText) => {
    setEditedActionValues(values => values.map((val) => (val.id == valId) ? {...val, text: newText} : val))
  }

  return (<Box sx={{display:'flex', flexDirection:'column', gap: '14px', paddingTop:"4px"}}>
    <Box sx={{display:'flex', justifyContent:'space-between'}}>
      <Box sx={{fontWeight: 'medium'}}>{step.taskName || step.taskId || ""}</Box>
      <Typography sx={{fontSize:'14px', color:'#ccc', textAlign:'right'}}>{getDateStr(step.timestamp) + ' at ' + getTimeStr(step.timestamp)}</Typography>
    </Box>
    {step.taskDesc && <Box sx={{color:'#aaa', fontSize:'14px'}}>{step.taskDesc}</Box>}
    <Box sx={{display:'flex', flexDirection:'column', alignItems:'end', padding:'20px', gap: '18px', background:'#eee', borderRadius:'8px', boxShadow: 2}}>
      {editedActionValues && editedActionValues.filter(val => !val.hide).map((value) => <Box key={value.id} sx={{display:'flex', flexDirection:'column', gap: '8px', width:'100%'}}>
        {value.label && <Box sx={{paddingLeft:'10px', typography: 'subtitle2', fontFamily: 'Monospace'}}><Tooltip title={`id: ${value.id}`} placement="right">{value.label}</Tooltip></Box>}
        {(value.text != null) && !editing && <Box sx={{background:'#f8f8f8', borderRadius:'4px', padding:'10px', whiteSpace:'pre-line'}}>{value.text || <>&nbsp;</>}</Box>}
        {(value.text != null) && editing && <TextField
          fullWidth
          multiline
          maxRows={8}
          sx={{background:'#f8f8f8', whiteSpace:'pre-line'}}
          value={value.text}
          onChange={(event) => {
            onValueEdit(value.id, event.target.value);
          }}
        />}
        {step.allowEditing && <Box sx={{display:'flex'}}>
          {!editing && <Button variant="outlined" color="secondary" onClick={()=>setEditing(true)} startIcon={<EditIcon />}>Edit</Button>}
          {editing && <Button variant="outlined" color="secondary" onClick={()=>setEditing(false)} startIcon={<DoneIcon />}>Done</Button>}
        </Box>}
      </Box>)}
      <Box sx={{display:'flex', gap:'20px'}}>
        {step.state===RUN_STATE.REQUESTED_HUMAN && <Button variant="contained" color="success" disabled={!run.agent.webhook || (humanReactTrigger !== TRIGGER.IDLE)} onClick={()=>setHumanReactTrigger(TRIGGER.APPROVE)} startIcon={(humanReactTrigger === TRIGGER.APPROVE) ? <CircularProgress color="success" size={24} /> : <DoneIcon />}>Approve</Button>}
        {step.state===RUN_STATE.REQUESTED_HUMAN && <Button variant="outlined" color="error" disabled={!run.agent.webhook || (humanReactTrigger !== TRIGGER.IDLE)} onClick={()=>setHumanReactTrigger(TRIGGER.REJECT)} startIcon={(humanReactTrigger === TRIGGER.REJECT) ? <CircularProgress color="error" size={24} /> : <BlockIcon />}>Reject</Button>}
        {step.state===RUN_STATE.SERVED_TO_HUMAN && <Button variant="outlined" color="secondary" disabled={(humanReactTrigger !== TRIGGER.IDLE)} onClick={()=>setHumanReactTrigger(TRIGGER.ARCHIVE)} startIcon={(humanReactTrigger === TRIGGER.ARCHIVE) ? <CircularProgress size={24} /> : <DoneIcon />}>Archive</Button>}
      </Box>
      {step.state===RUN_STATE.REQUESTED_HUMAN && !run.agent.webhook && <Typography sx={{color:'red', fontSize:'14px'}}>{isDevOrAdmin ? "HTTP endpoint missing! Please add it for your agent in the settings (⚙️ icon above)." : "URL missing! Please ask your developer or admin to complete setup."}</Typography>}
    </Box>
  </Box>)
}

const RunStepForHumanSelection = ({accountId, accountPath, user, step, run}) => {
  const [humanReactTrigger, setHumanReactTrigger] = useState(TRIGGER.IDLE);
  const [selectedValues, setSelectedValues] = useState([])
  const selectedAny = selectedValues.length > 0
  const runDocId = run.docId
  const role = (!user || !user.access || !accountPath) ? null : user.access[accountPath];
  const isDevOrAdmin = !!role && ['dev', 'admin'].includes(role);
  
  useEffect(() => {
    if (humanReactTrigger === TRIGGER.IDLE) return () => { };
    const stateAfterReaction = (humanReactTrigger === TRIGGER.APPROVE) ? RUN_STATE.APPROVED : RUN_STATE.REJECTED
    const subscription = Data.getInstance().sendHumanReaction(user.id, user.email, accountId, runDocId, step.taskId, step.taskName, selectedValues, stateAfterReaction, HUMAN_REVIEW_SUB_STATES.MULTI_SELECT_FAN_OUT)
      .subscribe((x) => {
          setHumanReactTrigger(TRIGGER.IDLE);
        },
      );

    return () => {
      subscription.unsubscribe();
    };
  }, [accountId, runDocId, humanReactTrigger]);

  const onValueChange = (event) => {
    setSelectedValues((state) => {
      const newSelected = [...state.filter((val) => val.id !== event.target.name)]
      if (event.target.checked) {
        newSelected.push(step.actionValues.find((v) => v.id === event.target.name))
      }
      return newSelected
    })
  }

  return (<Box sx={{display:'flex', flexDirection:'column', gap: '14px', paddingTop:"4px"}}>
    <Box sx={{display:'flex', justifyContent:'space-between'}}>
      <Box sx={{fontWeight: 'medium'}}>{step.taskName || step.taskId || ""}</Box>
      <Typography sx={{fontSize:'14px', color:'#ccc', textAlign:'right'}}>{getDateStr(step.timestamp) + ' at ' + getTimeStr(step.timestamp)}</Typography>
    </Box>
    {step.taskDesc && <Box sx={{color:'#aaa', fontSize:'14px'}}>{step.taskDesc}</Box>}
    <Box sx={{display:'flex', flexDirection:'column', alignItems:'start', padding:'20px', gap: '18px', background:'#eee', borderRadius:'8px', boxShadow: 2}}>
      <FormGroup sx={{gap:'20px'}}>
      {step.actionValues && step.actionValues.map((value) => <Box key={value.id} sx={{display:'flex', flexDirection:'column', gap: '8px', width:'100%'}}>

        {value.label && <Box sx={{paddingLeft:'10px', typography: 'subtitle2', fontFamily: 'Monospace'}}><Tooltip title={`id: ${value.id}`} placement="right">{value.label}</Tooltip></Box>}
        <FormControlLabel control={<Checkbox checked={selectedValues.findIndex((v) => v.id === value.id)>=0} onChange={onValueChange} name={value.id} />} label={value.text} />
      </Box>)}
      </FormGroup>
      <Box sx={{display:'flex', gap:'20px'}}>
        <Button variant="contained" color="success" disabled={!selectedAny || !run.agent.webhook || (humanReactTrigger !== TRIGGER.IDLE)} onClick={()=>setHumanReactTrigger(TRIGGER.APPROVE)} startIcon={(humanReactTrigger === TRIGGER.APPROVE) ? <CircularProgress color="success" size={24} /> : <DoneIcon />}>Confirm</Button>
        <Button variant="outlined" color="error" disabled={!run.agent.webhook || (humanReactTrigger !== TRIGGER.IDLE)} onClick={()=>setHumanReactTrigger(TRIGGER.REJECT)} startIcon={(humanReactTrigger === TRIGGER.REJECT) ? <CircularProgress color="error" size={24} /> : <BlockIcon />}>Reject</Button>
      </Box>
      {step.state===RUN_STATE.REQUESTED_HUMAN && !run.agent.webhook && <Typography sx={{color:'red', fontSize:'14px'}}>{isDevOrAdmin ? "HTTP endpoint missing! Please add it for your agent in the settings (⚙️ icon above)." : "URL missing! Please ask your developer or admin to complete setup."}</Typography>}
    </Box>
  </Box>)
}

//'archived' might be done via API as well actually
const RunStepHumanReacted = ({step}) => {
  return (<Box sx={{display:'flex', flexDirection:'column', gap: '8px'}}>
    <Box sx={{display:'flex', paddingTop:"4px", justifyContent:'space-between'}}>
      {(step.state === RUN_STATE.REJECTED) && <Typography sx={{color:'red', fontSize:'14px', fontWeight: 'medium'}}>{`Rejected by ${step.userEmailResponded}`}</Typography>}
      {(step.state === RUN_STATE.APPROVED) && <Typography sx={{color:'green', fontSize:'14px', fontWeight: 'medium'}}>{`Approved by ${step.userEmailResponded}`}</Typography>}
      {(step.state === RUN_STATE.ARCHIVED) && <Typography sx={{fontSize:'14px', fontWeight: 'medium'}}>{`Archived${step.userEmailResponded ? ` by ${step.userEmailResponded}` : ""}`}</Typography>}
      <Typography sx={{fontSize:'14px', color:'#ccc', textAlign:'right'}}>{getDateStr(step.timestamp) + ' at ' + getTimeStr(step.timestamp)}</Typography>
    </Box>
    {step.actionValues && step.actionValues.filter(val => !val.hide).map((value) => <RunApprovedActionValue key={value.id} value={value} /> )}
  </Box>)
}

const RunApprovedActionValue = ({value}) => {
  const [expanded, setExpanded] = useState(false);
  return <Box sx={{display:'flex', flexDirection:'column', gap: '8px', width:'100%'}}>
    {(value.label || value.wasEdited) && <Box sx={{display:'flex', flexDirection:'row', gap: '8px', paddingLeft:'10px'}}>
      {value.label && <Box sx={{typography: 'subtitle2', fontFamily: 'Monospace'}}><Tooltip title={`id: ${value.id}`} placement="right">{value.label}</Tooltip></Box>}
      {value.wasEdited && <Box sx={{typography: 'caption'}}><i>edited</i></Box>}
    </Box>}
    {value.text && <Box sx={{background:'#f8f8f8', borderRadius:'4px', padding:'10px', whiteSpace:'pre-line'}}>{expanded ? value.text : previewString(value.text)}{shortenString(value.text)[1] ? <Typography sx={{display: 'inline', paddingLeft:'8px', fontSize:'14px', color:'#ccc', cursor:'pointer'}} onClick={()=>setExpanded(val => !val)}>{expanded ? 'Show less' : 'Show more'}</Typography> : ""}</Box>}
  </Box>
}

const DropDownMenu = ({accountId, user, runDocId}) => {
  
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [archiveTrigger, setArchiveTrigger] = useState(false);
  const [deleteTrigger, setDeleteTrigger] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleArchive = () => {
    setArchiveTrigger(true);
  };
  const handleDelete = () => {
    setDeleteTrigger(true);
  }
  
  useEffect(() => {
    if (!archiveTrigger) return () => { };
    const subscription = Data.getInstance().sendHumanReaction(user.id, user.email, accountId, runDocId, null, null, null, RUN_STATE.ARCHIVED)
      .subscribe((x) => {
        setArchiveTrigger(false);
        },
      );

    return () => {
      subscription.unsubscribe();
    };
  }, [accountId, runDocId, archiveTrigger]);
  
  useEffect(() => {
    if (!deleteTrigger) return () => { };
    Data.getInstance().deleteDoc(`Accounts/${accountId}/AgentRuns/${runDocId}`).then(() => {
      setDeleteTrigger(false)
    }).catch((error) => {
      console.error("Couldn't delete run", error)
      setDeleteTrigger(false)
    });
  }, [deleteTrigger]);

  return (
    <Box sx={{padding:'10px'}}>
      {deleteTrigger && <Typography variant='subtitle'>Deleting...</Typography>}
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: '20ch',
          },
        }}
      >
          <MenuItem key={"archive"} onClick={handleArchive}>
            Archive
          </MenuItem>
          <MenuItem key={"delete"} onClick={handleDelete}>
            Delete
          </MenuItem>
      </Menu>
    </Box>
  );
}