import React, {useState, useEffect, useContext} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import AgentRun from './AgentRun';

const AgentRunPage = () => {
  const {runDocId} = useParams();

  return (<Box sx={{
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '40px',
    paddingBottom: '40px',
    background: '#f1f1f1',
    overflowY: 'auto',
    overflowX: 'hidden',
  }}>
    <AgentRun runDocId={runDocId} />
  </Box>)
};

export default AgentRunPage;