import React, {useState, useEffect, createContext} from 'react';
import { useNavigate, useLocation, Navigate } from 'react-router-dom';
import { CardContent, Typography, Grid, Card, Box, TextField, Button, CircularProgress } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Data from './Data';

// FirebaseManager.getInstance().init();

const CreateAccount = ({user}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [input, setInput] = useState("");
  const [createTrigger, setCreateTrigger] = useState(false);
  const [err, setErr] = useState(false);
  const [createdAccApp, setCreatedAccApp] = useState();
  // const usersEmailVerified = user.emailVerified //deactivated so that template -> sign up -> create org -> add template flow is not disrupted
  const usersEmailVerified = true;

  useEffect(() => {
    console.log('createTrigger ' + createTrigger);
    if (createTrigger && input.length) {
      // fetch(`https://api.appviewer.io/createAccountApp?userId=${user.id}&appName=${input}`)
      Data.getInstance().createAccountForUser(input)
      .then((data) => {
         console.log(data);
         setCreatedAccApp([data.account])
         console.log('CreateAcc navigating to ' + `/accounts/${data.account}${location.state?.fromPath || ''}`);
         navigate(`/accounts/${data.account}${(location.state?.fromPath && location.state?.fromPath.startsWith('/add-agent-from-template')) ? location.state?.fromPath : ''}`, {replace: true, state: {deployUrl: location.state?.deployUrl}})
      })
      .catch((err) => {
         console.log(err.message);
      });
    }
  }, [createTrigger]);

  /* useEffect(() => {
    console.log(`createdApp ${createdAccApp} user ${user.apps ? user.apps.map((el) => el.id) : "noApps"}`);
    if (!createdAccApp || !user.apps || !user.apps.length || !user.apps.findIndex((el) => el.id !== createdAccApp[1]) < 0) return;
    navigate("/", {replace: true})
  }, [createdAccApp, user]); */

  useEffect(() => {
    const regex = /^[a-zA-Z0-9](?:[a-zA-Z0-9_-]*[a-zA-Z0-9])?$/;
    setErr(input.length > 0 && (input.length > 40 || !regex.test(input)));
  }, [input]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (!(!input.length || err || createTrigger)) {
        setCreateTrigger(true)
      }
    }
  }

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: '80px',
      paddingLeft: '50px',
      paddingRight: '50px',
      paddingBottom: '40px',
      textAlign: 'center',
    }}>
      <Box sx={{
        maxWidth: '600px',
        fontSize: '14px',
      }}>
      <Typography variant='h6'>Set up a new organization</Typography>
        <Card sx={{
          height: '180px',
          width: '280px',
          cursor: 'pointer',
          marginTop: '24px',
          marginBottom: '24px',
        }}>
          <CardContent sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
          }}>
          <TextField id="input-app-name" label="Acme org" variant="standard" sx={{marginBottom: '24px'}} disabled={!usersEmailVerified} value={input} onChange={(ev) => setInput(ev.target.value)} onKeyDown={handleKeyDown} />
            <Button
            variant="outlined"
              disabled={!input.length || err || createTrigger || !usersEmailVerified}
              startIcon={createTrigger ? <CircularProgress size={24} /> : <AddCircleOutlineIcon/>}
              onClick={() => {
                setCreateTrigger(true)
              }}>Create organization</Button>
              {err && <Box sx={{color:'red', padding:'4px'}}>Invalid name. Only allows a-z, A-Z, 0-9, hyphen, underscores.</Box>}
              {!usersEmailVerified && <Box sx={{color:'red', padding:'4px'}}>Please check your inbox and confirm your email address first.</Box>}
          </CardContent>
        </Card>
      </Box>
        <Box sx={{
          maxWidth: '600px',
          fontSize: '14px',
        }}>
        <Typography variant='h6' sx={{marginTop: '24px'}}>Join an existing organization</Typography>
        <Box sx={{marginTop: '14px'}}>Ask the owner/admin for an invite (pass your user ID: {user.id})</Box>
          </Box>
    </Box>
  );
};

export default CreateAccount;
