import React, {useState, useEffect, useContext} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { experimentalStyled as styled } from '@mui/material/styles';
import { Typography, List, ListItem, ListItemButton, ListItemIcon, ListItemText, LinearProgress, Box, Paper, Link } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {catchError} from 'rxjs/operators';
import Data from './Data';
import InboxItem from './InboxItem';
import { AccountContext, UserContext } from './AccountFrame';

const Inbox = () => {
  const { account } = useContext(AccountContext);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [runs, setRuns] = useState([]);
  const [agents, setAgents] = useState();
  const [templates, setTemplates] = useState([]);
  const agentCount = agents && agents.length;
  const role = (!user || !user.access || !account) ? null : user.access[account.path];
  const isDevOrAdmin = !!role && ['dev', 'admin'].includes(role);
  
  useEffect(() => {
    setLoading(true);
    setError(null);
    const subscription = Data.getInstance().streamRuns(`Accounts/${account.id}`)
        .pipe(catchError(() => {
          setError('There was an error loading the AI agent runs.');
          setLoading(false);
        }))
        .subscribe(
            (runs) => {
              setLoading(false);
              setRuns([...runs]);
            },
        );

    return () => {
      subscription.unsubscribe();
    };
  }, [account]);
  
  useEffect(() => {
    // if (!isDevOrAdmin) return () => { };
    const subscription = Data.getInstance().streamAgentConnectors(`Accounts/${account.id}`)
        .pipe(catchError((err) => console.error('Error loading agents.', err)))
        .subscribe(
            (agents) => setAgents(agents),
        );

    return () => {
      subscription.unsubscribe();
    };
  }, [account]);
  
  useEffect(() => {
    const subscription = Data.getInstance().streamAgentTemplates()
        .pipe(catchError((err) => {
          console.error('There was an error loading the templates.', err);
        }))
        .subscribe(
            (templates) => {
              console.log("received templs " + templates.length)
              setTemplates(templates);
            },
        );

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const handleListItemClick = (runClicked) => {
    console.log("handleListItemClick " + runClicked.docId)
    navigate(`/accounts/${account.id}/runs/${runClicked.docId}`);
  }

  const onAgentClick = (agentClicked) => {
    console.log("onAgentClick " + agentClicked.docId)
    navigate(`/accounts/${account.id}/agents/${agentClicked.docId}`);
  }

  return (<Box sx={{
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '40px',
    paddingBottom: '20px',
    background: '#f1f1f1',
    overflowY: 'auto',
    overflowX: 'hidden',
  }}>
    <Typography variant="h5" sx={{ width: '90%', maxWidth: '1040px', marginBottom: '20px'}}>Active runs</Typography>
    <Paper sx={{ width: '90%', maxWidth: '1040px' }}>
      {loading && <LinearProgress color="secondary" />}
      {error && <Box sx={{color: 'red', padding:'10px', textAlign:'center'}}>{error}</Box>}
      <List sx={{ paddingTop:'40px', paddingBottom:'40px', }}>
      {!runs.length && <Box sx={{color: '#ccc', padding:'10px', textAlign:'center'}}>Running agents will appear here.</Box>}
      {(agentCount == 0) && <Box sx={{color: '#ccc', padding:'10px', textAlign:'center'}}>To get started, read our <Link href="https://docs.gotohuman.com" underline="hover">developer docs</Link> and fetch your API key clicking the 🔑 icon above.</Box>}
      {runs.map((run, index) => {
        return <InboxItem key={run.agentId+'-'+run.runId} run={run} inclDivider={index > 0} onItemClick={handleListItemClick} />
      })}
      </List>
    </Paper>
    {(agentCount > 0) && <Typography variant="h5" sx={{ width: '90%', maxWidth: '1040px', marginTop: '40px', marginBottom: '20px'}}>Your agents</Typography>}
    {(agentCount > 0) && <Box sx={{ width: '90%', maxWidth: '1040px' }}><Grid container spacing={3}>
      
        {agents && agents.map((agent) => <Grid key={agent.docId} xs={12} sm={6} md={4}><Paper sx={{padding:'20px', minHeight:'120px', cursor:'pointer', overflowX:'hidden'}} onClick={() => onAgentClick(agent)}><Typography variant="h6">{agent.name}</Typography></Paper></Grid>)}
      
    </Grid>
    </Box>}
    {templates && (templates.length > 0) && <Box sx={{display:'flex', flexDirection:'column', width: '90%', maxWidth: '1040px'}}>
    <Typography variant="h5" sx={{ marginTop: '40px', marginBottom: '20px'}}>Get started in 1 min. with one of our templates</Typography>
        <Grid container spacing={3}>
          {templates.map((template) => 
            <Grid key={template.docId} xs={12} sm={6} md={4}><Paper sx={{padding:'20px', minHeight:'120px', overflowX:'hidden'}}>
                <Typography variant="h6" sx={{marginBottom:'22px'}}>{template.template.name}</Typography>
                <Typography variant="body2" sx={{marginBottom:'22px', lineHeight:'18px'}}>{template.desc}</Typography>
                {template.vercelLink && <a href={template.vercelLink} target="_blank"><img src="https://vercel.com/button" alt="Deploy with Vercel"/></a>}
                {template.repo && <Typography variant="subtitle1" component="div" sx={{ lineHeight: '20px', marginTop:'8px' }} ><Link href={template.repo} target="_blank" color="#888">{template.repo}</Link></Typography>
                }
            </Paper></Grid>
          )}
        </Grid>
      </Box>
    }
  </Box>)
};

export default Inbox;