import React, {useState, useEffect} from 'react';
import {Outlet, useLocation} from 'react-router-dom';
import UserBar from './UserBar';
import {Box} from '@mui/material';
// import ConfirmEmail from "./ConfirmEmail";

const Window = ({user}) => {
  // const [appSelected, setAppSelected] = useState();
  const location = useLocation();
  useEffect(() => {
    console.log(`Location changed ${location.pathname} ${location.state?.fromPath} ${location.state?.deployUrl}`);
  }, [location]);
  return (
    <div>
      <UserBar user={user} 
      // onNavigateBack={() => setAppSelected(null)} 
      />
      <Box sx={{
        // padding: 50,
        // paddingTop: 20,
        // paddingBottom: 20,
        height: 'calc(100vh - 64px)',
        background: '#fafafa',
      }}>
        <Outlet />
      </Box>
    </div>
  );
};

export default Window;
