import React, {useEffect, useState} from 'react';
import {BrowserRouter, Routes, Route, Navigate, useLocation, useParams, useSearchParams} from 'react-router-dom';
import Login from './Login';
import Data from './Data';
import {LinearProgress} from '@mui/material';
import Window from './Window';
import AccountFrame from './AccountFrame';
import CreateAccount from './CreateAccount';
import Inbox from './Inbox';
import Settings from './Settings';
import Agent from './Agent';
import AddFromTemplate from './AddFromTemplate';
import AgentRunPage from './AgentRunPage';
// import ConfirmEmail from "./ConfirmEmail";

// FirebaseManager.getInstance().init();

const LoginState = {Init: 'Init', UnconfirmedEmail: 'UnconfirmedEmail', LoggedIn: 'LoggedIn', LoggedOut: 'LoggedOut'};

function AppRoot() {
  const [user, setUser] = useState('init');
  const loginState = (user === 'init' ? LoginState.Init : (user ? (user.emailVerified === true ? LoginState.LoggedIn : LoginState.UnconfirmedEmail) : LoginState.LoggedOut))

  useEffect(() => {
    const subscription = Data.getInstance().streamLoginState()
        .subscribe(
        // (user) => this.setState({isSignedIn: !!user})
            (user) => {
              console.log('Logged in ' + (user ? JSON.stringify(user) : 'no user'));
              setUser(user);
            },
        );

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const loginOk = (loginState === LoginState.LoggedIn || loginState === LoginState.UnconfirmedEmail) && user
  if (loginState === LoginState.Init) return <LinearProgress color="secondary" />;
  return (
    <BrowserRouter>
    <Routes>
            <Route path="/" element={<Window user={user} />}>
              <Route path="signin" element={<Login loginOk={loginOk} />} />
              <Route path="signedIn" element={<ContinueAfterSignUp loginOk={loginOk} user={user} />} />
              <Route path="logout" element={<Logout hasUser={!!user} />} />
              <Route index element={<RootDefault loginOk={loginOk} user={user} />} />
              <Route path="createAccount" element={<CreateAccountChecked loginOk={loginOk} user={user} />} />
              <Route path="add-agent-from-template/:templateId" element={<AddFromTemplateChecked loginOk={loginOk} user={user} />} />
            </Route>
            <Route path="accounts/:accountId/" element={<AccountFrameChecked loginOk={loginOk} user={user} />}>
              <Route index element={<Inbox />} />
              <Route path="runs/:runDocId" element={<AgentRunPage />} />
              <Route path="agents/:agentDocId" element={<Agent />} />
              <Route path="settings" element={<Settings />} />
              <Route path="add-agent-from-template/:templateId" element={<AddFromTemplate />} />
            </Route>
            <Route
              path="*"
              element={
                <Navigate to="/" replace />
            }
            />
        </Routes>
    </BrowserRouter>
  );
};

export default AppRoot;

const RootDefault = ({loginOk, user}) => {
  if (!loginOk) return <Navigate to={"/signin"} replace />;
  console.log(`RootDefault user.accounts.length ${user.accounts?.length || 'none'}`)
  if (user.accounts && user.accounts.length >= 1) {
    return <Navigate to={user.accounts[0].path} replace />
  } else {
    return <Navigate to="/createAccount" replace />
  }
};

const CreateAccountChecked = ({loginOk, user}) => {
  const location = useLocation();
  console.log(`CreateAccountChecked`);
  if (!loginOk) return <Navigate to={"/signin"} replace />;
  console.log(`CreateAccountChecked user.accounts.length ${user.accounts?.length || 'none'} targetPath ${location.pathname}`)
  return <CreateAccount user={user} />
};

const AccountFrameChecked = ({loginOk, user}) => {
  const location = useLocation();
  useEffect(() => {
    console.log(`Location changed ${location.pathname} ${location.state?.fromPath} ${location.state?.deployUrl}`);
  }, [location]);
  console.log(`AccountFrameChecked`);
  if (!loginOk) return <Navigate to={"/signin"} replace state={{ fromPath: location.pathname }} />;
  console.log(`AccountFrameChecked user.accounts.length ${user.accounts?.length || 'none'} targetPath ${location.pathname}`)
  return <AccountFrame user={user} />
};

const AddFromTemplateChecked = ({loginOk, user}) => {
  const location = useLocation();
  console.log(`AddFromTemplateChecked`);
  let [searchParams, setSearchParams] = useSearchParams();
  const vercelDeployUrl = searchParams.get('deployment-url') || location.state?.deployUrl; //either first visit or coming from ContinueAfterSignUp 
  console.log(`AddFromTemplateChecked vercelDeployUrl ${vercelDeployUrl}`);
  const targetPath = location.pathname
  if (!loginOk) return <Navigate to={"/signin"} replace state={{ fromPath: targetPath, deployUrl: vercelDeployUrl }} />;
  console.log(`AddFromTemplateChecked user.accounts.length ${user.accounts?.length || 'none'} targetPath ${targetPath}`)
  if (user.accounts && user.accounts.length >= 1) {
    console.log("AddFromTemplateChecked nav to " + (user.accounts[0].path + targetPath))
    return <Navigate to={'/'+ user.accounts[0].path + targetPath} replace state={{deployUrl: vercelDeployUrl}}/>
  } else {
    return <Navigate {...{to:"/createAccount", replace: true, ...(targetPath ? { state: { fromPath: targetPath, deployUrl: vercelDeployUrl } } : {})}} />
  }
};

const ContinueAfterSignUp = ({loginOk, user}) => {
  const location = useLocation();
  console.log(`ContinueAfterSignUp`);
  const fromPath = location.state?.fromPath;
  const deployUrl = location.state?.deployUrl;
  if (!loginOk) return <Navigate to={"/signin"} replace />;
  console.log(`ContinueAfterSignUp user.accounts.length ${user.accounts?.length || 'none'} fromPath ${fromPath} deployUrl ${deployUrl}`)
  if (user.accounts && user.accounts.length >= 1) {
    return <Navigate to={fromPath || '/'} replace state={{deployUrl: deployUrl || null}} />
  } else {
    return <Navigate {...{to:"/createAccount", replace: true, ...(fromPath ? { state: { fromPath: fromPath, deployUrl: deployUrl } } : {})}} />
  }
};

const Logout = ({hasUser}) => {
  useEffect(() => {
    Data.getInstance().logout()
  }, [hasUser]);

  if (!hasUser) return <Navigate to={"/signin"} replace />;
  return <></>
};