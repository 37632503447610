import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import './index.css';
import AppRoot from './App';
import reportWebVitals from './reportWebVitals';
import CssBaseline from '@mui/material/CssBaseline';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {amber, lightBlue} from '@mui/material/colors';

const theme = createTheme({
  palette: {
    primary: amber,
    secondary: lightBlue,
  },
});

if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

const container = document.getElementById('root');
const root = ReactDOMClient.createRoot(container);
root.render(
    <React.StrictMode>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <AppRoot />
      </ThemeProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
