import React, {useState, useEffect, createContext} from 'react';
import {Outlet, useParams} from 'react-router-dom';
import UserBar from './UserBar';
import {Box, LinearProgress, Typography} from '@mui/material';
import Data from './Data';
// import ConfirmEmail from "./ConfirmEmail";

export const UserContext = createContext(); 
export const AccountContext = createContext();

const AccountFrame = ({user}) => {
  const {accountId} = useParams();
  const [loading, setLoading] = useState(true);
  const [accSelected, setAccSelected] = useState();

  useEffect(() => {
    setLoading(true)
    const subscription = Data.getInstance().streamAccount(`Accounts/${accountId}`)
        .subscribe(
            (account) => {
              setLoading(false)
              setAccSelected(account);
            },
        );

    return () => {
      subscription.unsubscribe();
    };
  }, [accountId]);

  return (
    <div>
      <UserBar user={user} currentAcc={accSelected} />
      <Box sx={{
        // padding: 50,
        // paddingTop: 20,
        // paddingBottom: 20,
        height: 'calc(100vh - 64px)',
        background: '#fafafa',
      }}>
        {loading &&  <LinearProgress color="secondary" />}
        {!loading && !accSelected && <Typography variant='subtitle2' sx={{padding:'40px'}}>Account not found</Typography>}
        {!loading && accSelected && <AccountContext.Provider value={{account: accSelected}}><UserContext.Provider value={{user: user}}>
            <Outlet />
          </UserContext.Provider></AccountContext.Provider>}
      </Box>
    </div>
  );
};

export default AccountFrame;