import React, {useState} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './css/UserBar.module.css';
import { Avatar, AppBar, Toolbar, Tooltip, Popover, IconButton, Typography, Button, Box, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, CircularProgress } from '@mui/material';
import Fingerprint from '@mui/icons-material/Fingerprint';
import Logout from '@mui/icons-material/Logout';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
// import ArrowBack from '@mui/icons-material/ArrowBack';
import { styled } from '@mui/material/styles';
import Data from './Data';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';

const AppBarStyled = styled(AppBar)(({ theme }) => ({
  backgroundColor: 'white',
  borderBottom: '1px solid #000'
}));

const IconButtonSpaced = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

const BigText = styled(Typography)({
  flexGrow: 1,
});

const PaddedText = styled(Typography)(({ theme }) => ({
  paddingRight: theme.spacing(2),
}));

const PaddedButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(2),
}));

const PaddedBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2),
}));

const AvatarSized = styled(Avatar)({
  margin: 'auto',
});

const UserBar = ({ user, currentAcc, onNavigateBack }) => {
  // console.log('POPUSER ' + user.id);
  const navigate = useNavigate();
  const [showCopied, setShowCopied] = useState(false);
  const [anchorUserIdEl, setAnchorUserIdEl] = useState(null);
  const [anchorKeyEl, setAnchorKeyEl] = useState(null);
  
  const [key, setKey] = useState();
  const [openUserAdd, setOpenUserAdd] = useState(false);
  const [userIdEntered, setUserIdEntered] = useState("");
  const [roleValue, setRoleValue] = useState("viewer");
  const [userAddTrigger, setUserAddTrigger] = useState(false);
  const [userIdAdded, setUserIdAdded] = useState();
  const [askedForUserDelete, setAskedForUserDelete] = useState(false);
  const [userDeleteTrigger, setUserDeleteTrigger] = useState(false);
  const [userDeleteFailed, setUserDeleteFailed] = useState(false);
  const [askedForAccountDelete, setAskedForAccountDelete] = useState(false);
  const [accountDeleteTrigger, setAccountDeleteTrigger] = useState(false);

  const role = (!user || !user.access || !currentAcc) ? null : user.access[currentAcc.path];
  const isDevOrAdmin = !!role && ['dev', 'admin'].includes(role);
  const isAdminForAccount = !!role && ['admin'].includes(role);

  useEffect(() => {
    setKey(null);
    if (!isDevOrAdmin || !currentAcc) return;
    const subscription = Data.getInstance().fetchApiKey(`Accounts/${currentAcc.id}`)
        .subscribe((key) => setKey(key));
    return () => {
      subscription.unsubscribe();
    };
  }, [currentAcc, isDevOrAdmin]);

  const handleCloseUserAdd = () => {
    setOpenUserAdd(false);
  };

  const handleClickOpenUserAdd = (e) => {
    e.preventDefault();
    setOpenUserAdd(true);
  };
  
  useEffect(() => {
    console.log('userAddTrigger ' + userAddTrigger);
    if (userAddTrigger && userIdEntered.length) {
      const idToAdd = userIdEntered;
      setUserIdEntered("");
      Data.getInstance().addUserToAccount(currentAcc.path, userIdEntered, roleValue)
      .then((data) => {
         console.log(data);
         setUserIdAdded(userIdEntered);
         setUserAddTrigger(false)
         // navigate("/", {replace: true})
      })
      .catch((err) => {
         console.log(err.message);
      });
    }
  }, [userAddTrigger]);

  useEffect(() => {
    if (!userDeleteTrigger) return;
    setUserDeleteFailed(false)
    Data.getInstance().deleteUser().then(() => {
      setUserDeleteTrigger(false)
      navigate("/", {replace: true})
    }).catch((error) => {
      console.error("Couldn't delete user", error)
      setUserDeleteFailed(true)
      setUserDeleteTrigger(false)
    });
  }, [userDeleteTrigger])

  useEffect(() => {
    if (!accountDeleteTrigger || !currentAcc) return;
    Data.getInstance().deleteDoc(currentAcc.path).then(() => {
      setAccountDeleteTrigger(false)
      handleClose();
    }).catch((error) => {
      console.error("Couldn't delete account", error)
      setAccountDeleteTrigger(false)
    });
  }, [accountDeleteTrigger])


  const handleClick = (event) => {
    setAnchorUserIdEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorUserIdEl(null);
    setShowCopied(false);
    setAskedForUserDelete(false);
    setAskedForAccountDelete(false);
    setUserDeleteFailed(false);
  };

  const handleKeyClick = (event) => {
    event.preventDefault();
    setAnchorKeyEl(event.currentTarget);
  };

  const handleKeyClose = () => {
    setAnchorKeyEl(null);
    setShowCopied(false);
  };

  const copy = (text) => {
    navigator.clipboard.writeText(text);
    setShowCopied(true);
  }

  const open = Boolean(anchorUserIdEl);
  const id = open ? 'simple-popover' : undefined;
  const openKey = Boolean(anchorKeyEl);
  const idKey = openKey ? 'simple-popover' : undefined;

  let userDiv = '';
  let avatarDiv;
  const userEmail = user && user.email && user.email.length ? user.email : '';
  if (user) {
    avatarDiv = ((user.photoURL && user.photoURL.length) ? (<AvatarSized alt={user.name} src={user.photoURL} />) : (<AvatarSized>{user.name && user.name.length ? user.name.charAt(0) : (user.email && user.email.length ? user.email.charAt(0) : '')}</AvatarSized>));
  }
    userDiv = (
      user ? (<Box><Popover
        id={id}
        open={open}
        anchorEl={anchorUserIdEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box sx={{display: 'flex', flexDirection:'column', gap:'6px', alignItems:'start', padding:'10px'}}>
          <Typography variant='caption' sx={{}}>{userEmail}</Typography>
          <Tooltip title={user.id} placement='top'>
            <Button color="inherit" startIcon={<Fingerprint />} onClick={() => copy(user.id)}>{showCopied ? 'Copied \u2713' : 'Copy user ID'}</Button>
          </Tooltip>
          <Button color="inherit" startIcon={<Logout />} onClick={() => {handleClose(); navigate('/logout');}}>Logout</Button>
          {(!user.accounts || (user.accounts.length == 0)) && (askedForUserDelete ? <Box sx={{display: 'flex', flexDirection:'column'}}>Are you sure?<Box sx={{display: 'flex'}}><Button color="error" disabled={userDeleteTrigger} onClick={() => {setUserDeleteTrigger(true);}} startIcon={userDeleteTrigger ? <CircularProgress size={24} /> : <DeleteOutlineOutlinedIcon/>}>Delete</Button><Button disabled={userDeleteTrigger} onClick={() => setAskedForUserDelete(false)}>Cancel</Button></Box></Box> : <Button color="error" onClick={() => setAskedForUserDelete(true)}>Delete user</Button>)}
          {userDeleteFailed && <Typography color="error">Please log out and in and try again.</Typography>}
          {isAdminForAccount && (askedForAccountDelete ? <Box sx={{display: 'flex', flexDirection:'column'}}>Are you sure?<Box sx={{display: 'flex'}}><Button color="error" disabled={accountDeleteTrigger} onClick={() => {setAccountDeleteTrigger(true);}} startIcon={accountDeleteTrigger ? <CircularProgress size={24} /> : <DeleteOutlineOutlinedIcon/>}>Delete</Button><Button disabled={accountDeleteTrigger} onClick={() => setAskedForAccountDelete(false)}>Cancel</Button></Box></Box> : <Button color="error" onClick={() => setAskedForAccountDelete(true)}>Delete org</Button>)}
        </Box>
      </Popover>
        <Tooltip title={user.name && user.name.length ? (`${user.name} (${userEmail})`) : userEmail}>
          <IconButton onClick={handleClick}>{avatarDiv}</IconButton>
        </Tooltip>
      </Box>) : (
        <Box>
          <Button component={Link} to={`/signin`} sx={{ color: '#fff' }}>Sign In</Button>
      </Box>
      )
    );
    // const title = <BigText variant="h6" >{currentApp ? currentApp.name : 'Appviewer'}</BigText>;
    /* let navigation = '';
    if (currentApp) {
      navigation = (
        <IconButtonSpaced edge="start" color="inherit" onClick={onNavigateBack}>
          <ArrowBack />
        </IconButtonSpaced>
      );
    } */
  // }
  let keyBtn = "";
  if (key && currentAcc) {
    keyBtn = (
      <div>
        <Tooltip title="Show API key">
          <IconButton color="inherit" onClick={handleKeyClick}>
            <VpnKeyOutlinedIcon />
          </IconButton>
        </Tooltip>
        <Popover
          id={idKey}
          open={openKey}
          anchorEl={anchorKeyEl}
          onClose={handleKeyClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <PaddedBox>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <PaddedText><b>Your API key:</b> {key}</PaddedText>
                <PaddedText><b>Your Org Id:</b> {currentAcc.id}</PaddedText>
                {/* <Button onClick={() => copy(key)}>{showCopied ? 'Copied \u2713' : 'Copy'}</Button> */}
              </Box>
            </Box>
          </PaddedBox>
        </Popover>
      </div>
    );
  }
  let settingsBtn = "";
  if (isDevOrAdmin) {
    settingsBtn = (
      <Tooltip title="Settings">
          <IconButton color="inherit" onClick={() => navigate(`/accounts/${currentAcc.id}/settings`)}>
            <SettingsIcon />
          </IconButton>
        </Tooltip>
    )
  }
  
  return (
    <div sx={{
      flexGrow: 1,
      position: 'sticky',
      top: 0,
      zIndex: 100,
    }}>
      <AppBar position="static">
        <Toolbar>
          {/* {navigation} */}
          {/* {title} */}
          <Box className={styles.logoDiv} component={Link} to={`/`}>
            <img
            srcSet="/txtlogo166.png 1x, /txtlogo332.png 2x"
            sizes="(max-width: 166px) 100vw, 166px"
             src="/txtlogo166.png" className={styles.logo} />
            </Box>
          <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
            {currentAcc && <>
            <Box sx={{ display: 'flex', alignItems: 'center', border: 0, borderRadius: 1, borderColor: '#aaa', padding: '4px', width: 'auto', height: '40px', color:'white', background:'#edad00' }}>
              <Box component={Link} to={`/accounts/${currentAcc.id}`} sx={{color:'white', textDecoration:'none'}}>
                {/* <Box sx={{ fontSize: 8 }} >Project</Box> */}
                <Tooltip title={currentAcc.name}><Box sx={{ display: 'flex', alignItems: 'center', fontSize: 14 }} >{currentAcc.name.substring(0,40)}</Box></Tooltip>
              </Box>
              {user && (<Tooltip title="Add users to project">
                <IconButton sx={{color:'white', marginLeft: '8px'}} onClick={handleClickOpenUserAdd}><PersonAddIcon /></IconButton>
              </Tooltip>)}
              {keyBtn}
              {settingsBtn}
            </Box>
            {/* <Box sx={{ padding: '10px' }}>
              <Button component={Link} to={`/accounts/${accountId}/apps/${appId}/monitor`} sx={{ color: '#fff' }}>Live Monitor</Button>
            </Box> */}
            </>}
          </Box>
          {userDiv}
        </Toolbar>
      </AppBar>
      {currentAcc && <Dialog open={openUserAdd} onClose={handleCloseUserAdd}>
        <DialogTitle>Invite User to project</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To add another user to your project, ask the person to sign up and pass you their user id.
            They'll be notified once added.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="User ID"
            fullWidth
            variant="standard"
            value={userIdEntered}
            onChange={(ev) => setUserIdEntered(ev.target.value)}
          />
          <FormControl>
            <FormLabel>Role:</FormLabel>
            <RadioGroup
              row
              name="role-radio-buttons-group"
              value={roleValue}
              onChange={(ev) => setRoleValue(ev.target.value)}
            >
              <FormControlLabel value="viewer" control={<Radio />} label="Viewer" />
              <FormControlLabel value="dev" control={<Radio />} label="Dev" />
              {(user && user.access && (user.access[currentAcc.path] === "admin")) && <FormControlLabel value="admin" control={<Radio />} label="Admin" />}
            </RadioGroup>
          </FormControl>
          {userIdAdded && <Box sx={{color:'green'}}>Added user {userIdAdded}</Box>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseUserAdd}>Dismiss</Button>
          <Button 
          disabled={!userIdEntered.length || userAddTrigger} 
          onClick={() => {setUserIdAdded(null); setUserAddTrigger(true);}}
          startIcon={userAddTrigger ? <CircularProgress size={24} /> : <PersonAddIcon/>}
          >Add User</Button>
        </DialogActions>
      </Dialog>}
    </div>
    /* <div className={styles.bar}>
      {navigation}
      {userDiv}
    </div> */
  );
};

export default UserBar;
