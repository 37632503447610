import React, { useState, useEffect } from 'react';
import { Typography, List, ListItem, ListItemAvatar, Avatar, ListItemIcon, ListItemText, IconButton, Box, ListItemButton, Divider } from '@mui/material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import Data from './Data';
import Dots from './Dots';
import RunDate from './RunDate';
import { RUN_STATE_GROUP } from './RunStates';

const InboxItem = ({run, inclDivider, onItemClick}) => {
  const [input, setInput] = useState("");

  const previewString = (agentRun) => {
    //show action, otherwise last context item
    const preStr = (agentRun.action || ((agentRun.context && agentRun.context.length) ? agentRun.context[agentRun.context.length-1] : ""));
    const shortened = preStr.slice(0,180)
    const toReturn = (shortened.length < preStr.length) ? (shortened + "...") : shortened;
    console.log("previewString " + preStr.length + " shortened: " + shortened.length);
    return toReturn;
  }

  return (<>
  {inclDivider && <Divider variant="inset" component="li" />}
  <ListItemButton onClick={() => onItemClick(run)}>
  <ListItem>
    <ListItemAvatar>
      <Avatar>{run.agent.name.charAt(0)}</Avatar>
    </ListItemAvatar>
    <ListItemText sx={{paddingRight:'40px'}}
          primary={run.stateGroup !== RUN_STATE_GROUP.RUNNING ? run.agent.name : <Box sx={{display:'flex', gap:'20px'}}>{run.agent.name}<Dots /></Box>}
          secondary={run.stateGroup !== RUN_STATE_GROUP.RUNNING ? (<React.Fragment >{previewString(run)}</React.Fragment>) : "" }
        />
        {/* {(run.state === RUN_STATE.RUNNING) && <Dots />} */}
    <RunDate timeStampDate={run.lastModified} />
    
  </ListItem>
  </ListItemButton>
  </>)
};

export default InboxItem;