import React, {useState, useEffect, useContext} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { experimentalStyled as styled } from '@mui/material/styles';
import { Typography, List, ListItem, ListItemButton, ListItemIcon, ListItemText, LinearProgress, CircularProgress, Box, Paper, Button, TextField } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import {catchError, filter, map} from 'rxjs/operators';
import Data from './Data';
import InboxItem from './InboxItem';
import { AccountContext, UserContext } from './AccountFrame';

const Agent = () => {
  const {agentDocId} = useParams();
  const { account } = useContext(AccountContext);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [runs, setRuns] = useState([]);
  const [agent, setAgent] = useState();
  const role = (!user || !user.access || !account) ? null : user.access[account.path];
  const isDevOrAdmin = !!role && ['dev', 'admin'].includes(role);
  const hasTriggerPayload = agent && agent.triggerPayloadLabel1;
  
  useEffect(() => {
    // if (!isDevOrAdmin) return () => { };
    const subscription = Data.getInstance().streamAgentConnectors(`Accounts/${account.id}`)
        .pipe(catchError((err) => console.error('Error loading agents.', err)), map((list) => list.find((item)=>item.docId == agentDocId)), filter((item)=> !!item))
        .subscribe(
            (agent) => setAgent(agent),
        );

    return () => {
      subscription.unsubscribe();
    };
  }, [account, agentDocId]);
  
  useEffect(() => {
    if (!agent) return () => { };
    setLoading(true);
    setError(null);
    const subscription = Data.getInstance().streamRuns(`Accounts/${account.id}`, agent.id)
        .pipe(catchError(() => {
          setError('There was an error loading the AI agent runs.');
          setLoading(false);
        }))
        .subscribe(
            (runs) => {
              setLoading(false);
              setRuns([...runs]);
            },
        );

    return () => {
      subscription.unsubscribe();
    };
  }, [account, agent]);

  const handleListItemClick = (runClicked) => {
    console.log("handleListItemClick " + runClicked.docId)
    navigate(`/accounts/${account.id}/runs/${runClicked.docId}`);
  }

  const onAgentClick = (agentClicked) => {
    console.log("onAgentClick " + agentClicked.docId)
    // navigate(`/accounts/${account.id}/agents/${agentClicked.docId}`);
  }

  return (<Box sx={{
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '40px',
    paddingBottom: '20px',
    background: '#f1f1f1',
    overflowY: 'auto',
    overflowX: 'hidden',
  }}>
    {agent && <Typography variant="h5" sx={{ width: '90%', maxWidth: '1040px', marginBottom: '0px'}}>{agent.name}</Typography>}
    {agent && <Typography variant="subtitle" sx={{ width: '90%', maxWidth: '1040px', marginBottom: '20px', color:'#ccc', fontSize:'14px'}}>{agent.id}</Typography>}
    <Paper sx={{ width: '90%', maxWidth: '1040px' }}>
      {loading && <LinearProgress color="secondary" />}
      {error && <Box sx={{color: 'red', padding:'10px', textAlign:'center'}}>{error}</Box>}
      <List sx={{ paddingTop:'40px', paddingBottom:'40px', }}>
      {!runs.length && <Box sx={{color: '#ccc', padding:'10px', textAlign:'center'}}>Active runs will appear here.</Box>}
      {runs.map((run, index) => {
        return <InboxItem key={run.agentId+'-'+run.runId} run={run} inclDivider={index > 0} onItemClick={handleListItemClick} />
      })}
      </List>
    </Paper>
    {agent && (agent.id !== 'com.gotohuman.demos.chatbot') && <ManualTrigger accountId={account.id} agent={agent} />}
  </Box>)
};

export default Agent;

//----

const ManualTrigger = ({accountId, agent}) => {
  const [tiggered, setTriggered] = useState(false);
  const [input, setInput] = useState("");
  const [err, setError] = useState(false);
  const [done, setDone] = useState(false);

  useEffect(() => {
    console.log('tiggered ' + tiggered);
    if (tiggered && (!agent.triggerPayloadLabel1 || input.length)) {
      setDone(false);
      setError(false)
      Data.getInstance().triggerAgentManually(accountId, agent.id, input.length ? [input] : null)
      .then((data) => {
         console.log(data);
         setDone(true);
         setInput("");
         setTriggered(false);
      })
      .catch((err) => {
        setError(true)
        setTriggered(false);
         console.log(err.message);
      });
    }
  }, [tiggered]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (!((!!agent.triggerPayloadLabel1 && !input.length) || tiggered)) {
        setTriggered(true)
      }
    }
  }
  return (<Box sx={{display:'flex', flexDirection:'column', marginTop:"20px"}}>
    <Box sx={{display:'flex', flexDirection:'row'}}>
      {agent.triggerPayloadLabel1 && <TextField id="input-trigger-payload" label={agent.triggerPayloadLabel1} variant="standard" sx={{marginRight:'10px'}} disabled={tiggered} value={input} onChange={(ev) => setInput(ev.target.value.trim())} onKeyDown={handleKeyDown} />}
      <Button
        variant="outlined"
        disabled={(!!agent.triggerPayloadLabel1 && !input.length) || tiggered}
        startIcon={tiggered ? <CircularProgress size={24} /> : <PlayArrowIcon/>}
        onClick={() => {
          setTriggered(true)
        }}>Run</Button>
    </Box>
      {err && <Box sx={{color:'red', padding:'4px'}}>There was an error triggering the agent.</Box>}
      {done && <Box sx={{color:'green', padding:'4px'}}>Triggered!</Box>}
  </Box>)
}