export const RUN_STATE = Object.freeze({
  TASK_RUNNING: "task_running",
  REQUESTED_HUMAN: "requested_human_approval",
  SERVED_TO_HUMAN: "served_to_human",
  TASK_DONE: "task_done",
  APPROVED: "human_approved",
  REJECTED: "human_rejected",
  ARCHIVED: "archived",
})

export const HUMAN_REVIEW_SUB_STATES = Object.freeze({
  MULTI_SELECT_FAN_OUT: "multi_select_fan_out",
});

export const RUN_STATE_GROUP = Object.freeze({
  RUNNING: "running",
  AT_HUMAN: "at_human",
  DONE: "done",
})

export const mapRunStateGroup = (runState) => {
  switch (runState) {
    case RUN_STATE.TASK_RUNNING:
    case RUN_STATE.TASK_DONE:
      return RUN_STATE_GROUP.RUNNING;
    case RUN_STATE.REQUESTED_HUMAN:
    case RUN_STATE.SERVED_TO_HUMAN:
      return RUN_STATE_GROUP.AT_HUMAN;
    default:
      return RUN_STATE_GROUP.DONE;
  }
}